@import "variables.scss";

/*  header css  */
.WebsiteHeader {
    padding: 10px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 767px) {
        padding: 10px 5px;
    }
    .brandName {
        display: flex;
        align-items: center;
    }
    .ten {
        position: inherit !important;
    }
    .logoImage {
        img {
            width: 60px !important;
        }
    }
    .veteranLogo.ui.image {
        max-width: 65px;
        margin-left: 20px;
    }
    .navigationBar {
        justify-content: center;
        // @media(max-width:767px){
        //     position: absolute;
        //     left: 0;
        //     width: 100%;
        //     top: 70px;
        //     right: 0;
        //     background-color: #fff !important;
        //     height: calc(100vh - 70px);
        //     overflow-y: auto;
        //     display: flex !important;
        //     flex-direction: column;
        //     align-items: flex-start;
        //     justify-content: flex-start;
        // }
        a {
            &:hover,
            &.active {
                background-color: transparent !important;
                color: $orange-color !important;
            }
        }
    }

    .tryFreeBtn {
        @media (max-width: 767px) {
            padding: 10px 20px !important;
            font-size: 12px !important;
            white-space: nowrap;
        }
    }
    .desktopOnly {
        @media (max-width: 767px) {
            display: none !important;
        }
    }
    .mobileOnly {
        @media (min-width: 767px) {
            display: none !important;
        }
    }
    .mobileMenu {
        position: fixed;
        width: 150px;
        top: 0;
        bottom: 0;
        right: -160px;
        background-color: #fff;
        z-index: 1;
        padding: 35px 15px 15px 15px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        transition: .2s;
        &.show {
            right: 0;
            transition: .2s;
        }
        .ui.secondary.menu {
            flex-direction: column;
            margin: 0 0 25px;
            .item {
                align-self: self-start;
                margin: 0;
                padding: 8px 5px;
                width: 100%;
            }
        }
        i.icon.close {
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }
}
