@import "variables.scss";

@font-face {
    font-family: "montserratregular";
    src: url("../fonts/montserrat-regular-webfont.woff2") format("woff2"),
        url("../fonts/montserrat-regular-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "latobold";
    src: url("../fonts/lato-bold-webfont.woff2") format("woff2"), url("../fonts/lato-bold-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "latolight";
    src: url("../fonts/lato-light-webfont.woff2") format("woff2"),
        url("../fonts/lato-light-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "latomedium";
    src: url("../fonts/lato-medium-webfont.woff2") format("woff2"),
        url("../fonts/lato-medium-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "latoregular";
    src: url("../fonts/lato-regular-webfont.woff2") format("woff2"),
        url("../fonts/lato-regular-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "latosemibold";
    src: url("../fonts/lato-semibold-webfont.woff2") format("woff2"),
        url("../fonts/lato-semibold-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "latothin";
    src: url("../fonts/lato-thin-webfont.woff2") format("woff2"), url("../fonts/lato-thin-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

body {
    font-family: "latoregular" !important;
    position: relative;
    // overflow: auto!important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "latosemibold" !important;
}

p {
    font-size: 13px;
    margin-bottom: 0 !important;
}

a {
    &:hover {
        transform: none !important;
    }
}

.pt-0 {
    padding-top: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mbt {
    margin-bottom: 10px !important;
}

.orange-color {
    color: $orange-color !important;
}

.maroon-color {
    color: $maroon-color !important;
}

.deepviolet-color {
    color: $deepviolet-color !important;
}

.gray-color {
    color: $spanish-gray !important;
}

.grayBg {
    background-color: $gray-box !important;
}

.whiteBg {
    background-color: $white-color !important;
}

.orangeBg {
    background-color: $orange-color !important;
}

.text-center {
    text-align: center;
}

.d-flex {
    display: flex;
}

.App {
    position: relative;

    .main-container {
        // margin-top: 100px;
        transition: all 0.5s ease;

        @media (max-width: 767px) {
            // margin-top: 20px;
        }
    }

    &.HeaderMenu {
        @media (max-width: 991px) {
            .header-top {
                transition: all 0.5s ease;
            }

            .left-sidebar {
                transition: all 0.5s ease;
                left: 0;

                .logo-menu {
                    display: none !important;
                }
            }

            .menubar {
                display: none;
            }

            .close {
                display: inline-block !important;
            }

            .sectionBg {
                display: block;
            }
        }
    }

    &.TabMenu {
        @media (max-width: 991px) {
            .header-top {
                left: 0 !important;
                transition: all 0.5s ease;
            }

            .left-sidebar {
                transition: all 0.5s ease;
                left: -100% !important;
            }
        }
    }

    &.menuCollapsed {
        .header-top {
            transition: all 0.5s ease;
            // left: 200px;
            // @media (min-width: 991px) {
            //     left: 60px !important;
            // }
        }

        .left-sidebar {
            transition: all 0.5s ease;
            left: 0;

            @media (min-width: 991px) {
                width: 60px;
            }

            .logo-menu {
                display: flex;
                align-items: center;
                justify-content: center;

                @media (max-width: 991px) {
                    display: none !important;
                }

                .item {
                    margin: auto !important;
                    display: block !important;
                }

                .company-logo {
                    opacity: 0 !important;
                    width: 0 !important;
                    overflow: hidden !important;
                    transition: all 0.1s ease;
                }
            }

            .main-menu {
                @media (min-width: 991px) {
                    .menu {
                        .item {
                            overflow: hidden !important;

                            img.ui.image {
                                float: initial;
                            }

                            span {
                                opacity: 0 !important;
                                width: 0 !important;
                                overflow: hidden !important;
                                transition: all 0.1s ease;
                            }

                            .icon {
                                float: initial !important;
                                margin: 0;
                            }
                        }
                    }
                }
            }

            .profile-menu {
                @media (min-width: 991px) {
                    .item {
                        justify-content: center;
                        display: flex;
                    }

                    .content {
                        display: none !important;
                    }

                    .profile-dropdown {
                        border-radius: 5px;
                        padding: 10px 5px !important;

                        .item.image-profile {
                            padding: 0 !important;
                        }
                    }
                }
            }

            // .iconView {
            //     -webkit-transition: all .15s ease;
            //     transition: all .15s ease;
            //     -webkit-transform: rotate(180deg);
            //     transform: rotate(180deg);
            // }
        }

        .main-container {
            transition: all 0.5s ease;
        }
    }
}

.orange-button {
    background-image: linear-gradient(to right, #f24852, #290e59) !important;
    color: $white-color !important;
    border: 1px solid $orange-color !important;

    &:hover {
        color: $orange-color !important;
        background-color: $white-color !important;
    }
}

.inc-dec-button {
    background-image: linear-gradient(to right, #f24852, #290e59) !important;
    color: $white-color !important;

    &:hover {
        color: $orange-color !important;
        background-color: $white-color !important;
    }
}

.deepviolet-button {
    background-color: $deepviolet-color !important;
    color: $white-color !important;
    border: 1px solid $deepviolet-color !important;

    &:hover {
        color: $deepviolet-color !important;
        background-color: $white-color !important;
    }
}

.purple-button {
    background-color: $purple-color !important;
    color: $white-color !important;
    border: 1px solid $purple-color !important;

    &:hover {
        color: $purple-color !important;
        background-color: $white-color !important;
    }
}

.custom-radio-btn {
    .hidden {
        &:checked {
            ~label {
                &:before {
                    border-color: $orange-color !important;
                    top: -3px !important;
                    width: 20px !important;
                    height: 20px !important;
                }

                &:after {
                    background-color: $orange-color !important;
                    top: -3px !important;
                    width: 20px !important;
                    height: 20px !important;
                }
            }
        }

        &:focus {
            ~label {
                &:before {
                    border-color: $orange-color !important;
                }
            }
        }
    }

    label {
        &:before {
            top: -3px !important;
            width: 20px !important;
            height: 20px !important;
        }

        &:after {
            top: -3px !important;
            width: 20px !important;
            height: 20px !important;
        }
    }
}

.cmn-shad {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
    padding: 15px !important;
    border-radius: 10px !important;
    margin-bottom: 20px !important;
}

.orange-btn {
    background-color: $orange-color !important;
    border: 1px solid $orange-color !important;
    color: $white-color !important;
    border-radius: 20px !important;
    padding: 8px 20px !important;
    font-size: 15px !important;
    line-height: normal !important;

    &:hover {
        background-color: $white-color !important;
        color: $orange-color !important;
    }
}

.orange-react-btn {
    background-image: linear-gradient(to right, #f24852, #290e59) !important;
    color: $white-color !important;
    border-radius: 5px !important;
    padding: 12px 20px !important;
    font-size: 15px !important;
    line-height: normal !important;
    font-family: "latosemibold" !important;
}

.white-react-btn {
    background-color: $white-color !important;
    color: $violet-color !important;
    border-radius: 5px !important;
    padding: 12px 20px !important;
    font-size: 15px !important;
    line-height: normal !important;
    font-family: "latosemibold" !important;
}

.blue-btn {
    background-color: $deepviolet-color !important;
    border: 1px solid $deepviolet-color !important;
    color: $white-color !important;
    border-radius: 20px !important;
    padding: 8px 20px !important;
    font-size: 15px !important;
    line-height: normal !important;

    &:hover {
        background-color: $white-color !important;
        color: $deepviolet-color !important;
    }
}

.locationselect {
    border: none !important;
    min-width: 130px !important;
    background: transparent !important;

    .menu {
        border-color: transparent !important;

        .item {
            &:hover {
                background-color: $orange-color !important;
                color: $white-color !important;
            }

            &.selected {
                background-color: $orange-color !important;
                color: $white-color !important;
            }
        }
    }
}

.common-search-bar {
    input {
        border-radius: 30px !important;

        &:focus {
            border-color: $spanish-gray !important;
        }
    }
}

.custome-checkbox {
    .hidden {
        &:checked {
            ~label {
                &:before {
                    border-color: $orange-color;
                }

                &:after {
                    color: $white-color !important;
                    background-color: $orange-color !important;
                    border-radius: 3px !important;
                }
            }
        }
    }
}

.custom-toggle {
    .hidden {
        &:checked {
            ~label {
                &:before {
                    background: $orange-color !important;
                }

                &:after {
                    background-color: $white-color !important;
                    left: 30px !important;
                    transition: 0.3s;
                }

                .disable {
                    display: none;
                }

                .enable {
                    display: block;
                }
            }
        }
    }

    label {
        padding-left: 60px !important;

        .enable {
            display: none;
        }

        &:before {
            width: 50px !important;
            height: 25px !important;
            top: -4px !important;
            border-radius: 50px !important;
            background-color: $gainsboro-color !important;
            border: none !important;
        }

        &:after {
            content: "" !important;
            width: 20px;
            height: 20px;
            background: $orange-color;
            border-radius: 50%;
            top: -2px;
            left: 4px !important;
            opacity: 1 !important;
            transition: 0.3s !important;
        }
    }
}

.custom-select {
    &.selection.dropdown {
        &:focus {
            border-color: $orange-color !important;
        }
    }

    .menu {
        @include horizontal-scroll;
        border-color: $orange-color !important;

        .item {
            &.selected {
                background: $orange-color !important;
                color: $white-color !important;
                border-color: $orange-color !important;
            }

            &:hover {
                background: $orange-color !important;
                color: $white-color !important;
                border-color: $orange-color !important;
            }
        }
    }
}

.borderlessTable {

    th,
    td {
        border: none !important;
    }
}

.profile-box {
    position: relative;

    &:before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        right: 0;
        background-color: $orange-color;
        height: 55px;
        border-radius: 10px 10px 0 0;
    }
}

.profile-box2 {
    position: relative;

    &:before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        right: 0;
        background-color: $orange-color;
        height: 55px;
        border-radius: 10px 10px 0 0;
    }
}

.custom-breadcrumb {
    .section {
        color: $black-color !important;

        &.active {
            color: $deepviolet-color !important;
        }
    }

    .icon {
        color: $black-color !important;
    }
}

.common-tabs {
    .secondary {
        &.menu {
            justify-content: flex-end;

            .item {
                border: 1px solid $deepviolet-color !important;

                &:hover {
                    background-color: $deepviolet-color !important;
                    color: $white-color !important;
                }

                &.active {
                    background-color: $deepviolet-color !important;
                    color: $white-color !important;
                }
            }
        }
    }

    .segment {
        border: none !important;
        padding: 0 !important;
    }

    .card {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
        margin-bottom: 20px !important;
        overflow: hidden;

        @media (min-width: 1199px) {
            &:hover {
                .right-icons {
                    right: 0;
                    transition: all 0.7s ease-out;
                }
            }
        }

        .right-icons {
            position: absolute;
            right: -100%;
            top: 6px;
            z-index: 1;
            transition: all 0.7s ease-in;

            @media (max-width: 1199px) {
                right: 0;
            }
        }

        .ui.large.image {
            height: 260px;
        }
    }
}

.bordered-input {
    border-radius: 20px !important;

    input {
        border-radius: 20px !important;
    }
}

.text-center-input {
    input {
        text-align: center !important;
    }
}

input {
    &:focus {
        border-color: $spanish-gray !important;
    }
}

.common-table {
    overflow-x: auto;
    @include vertical-scroll;

    .table {
        min-width: 1000px;
    }
}

.abc {
    &.removeScroll {
        overflow: initial !important;
    }
}

.addNotes {
    margin-top: -38px;
}

.ui.modal {
    .scrolling.content {
        @include horizontal-scroll;
        overflow-y: auto !important;
        overflow-x: hidden !important;
    }
}

.detail-label {
    text-transform: uppercase;
    color: $deepviolet-color;
    margin-bottom: 5px !important;
}

.note-icon {
    margin-left: 20px;
}

.error .label {
    margin: 5px 0px 0px 0px !important;
    font-weight: normal !important;
}

.ui.form .field.error label {
    color: rgba(0, 0, 0, 0.87) !important;
}

.categoryError {
    font-size: 25px;
    font-weight: bold;
    margin: 40px 0px 0 330px;
}

img.ui.image.emptyError {
    margin: 10% 0px 0px 23%;
}

.willOverflow {
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 5% 0px 0px 41%;
}

// img.ui.tiny.centered.image.mbt {
//     width: 76px;
//     height: 70px;
//     font-size: .85714286rem;
//     border-radius: 50px;
// }
.customer-card {
    img.ui.image {
        border-radius: 50% !important;
        height: 71px !important;
        width: 75px !important;
        object-fit: cover !important;
    }
}

.ui[class*="right floated"].button,
.ui[class*="right floated"].buttons {
    float: right;
    margin-right: 0;
    // margin-left: 56.25em!important;
    // margin-top: -37px;
    margin-left: 0;
}

.circleImg {
    max-width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;

    img.ui.image {
        width: 100% !important;
        height: 100% !important;
        object-fit: fill;
    }
}

section {
    width: 100%;
}

h2 {
    &.ui {
        &.header {
            &.subCaption {
                text-align: center;
                font-size: 40px;
                line-height: 48px;
                font-weight: 700;
                font-family: "latobold";
            }
        }
    }
}

h3 {
    &.ui {
        &.header {
            &.subCaption {
                font-size: 30px;
                line-height: 30px;
                font-weight: 600;
                font-family: "latobold";
                margin: 0;
            }
        }
    }
}

/* dashboard banner section */
.banner {
    position: relative;
    background-color: #f9f9f9;
    padding: 30px 0;

    .videoBanner {
        position: relative;
        padding: 8px;

        &:before {
            content: "";
            position: absolute;
            top: -6px;
            bottom: -6px;
            left: -6px;
            right: 0;
            border: 6px solid #f54952;
            border-right: none;
            width: 50px;
        }

        &:after {
            content: "";
            position: absolute;
            top: -6px;
            bottom: -6px;
            right: -6px;
            border: 6px solid #f54952;
            border-left: none;
            width: 50px;
            margin-left: auto;
        }

        .ui {
            .embed {
                video {
                    width: 100%;
                }
            }
        }
    }

    h2 {
        &.ui {
            &.header {
                &.subCaption {
                    text-align: left;
                }
            }
        }
    }

    .imageBanner {
        display: flex;
        align-items: center;
        height: 100%;
    }

    .videoSection {
        padding-left: 100px !important;
        display: flex;
        align-items: center;
        height: 100%;

        @media (max-width: 991px) {
            padding-left: 0 !important;
        }

        h1.ui.header {
            color: $orange-color;
            font-size: 28px;
            letter-spacing: 1px;

            @media (max-width: 991px) {
                font-size: 22px;
            }
        }

        p {
            font-size: 18px;

            @media (max-width: 991px) {
                font-size: 15px;
            }
        }

        .easyToUse {
            color: $violet-color;
            font-size: 18px;
            margin: 20px 0 30px;

            @media (max-width: 991px) {
                font-size: 15px;
                margin: 10px 0 20px;
            }
        }
    }
}

/*  dashboard css  */

.partnerSlider {
    padding: 50px 0 30px;

    h3.ui.header {
        color: $purple-color;
        font-size: 28px;
        letter-spacing: 1px;
        text-align: center;
    }

    .swiper-slide {
        text-align: center;

        img.ui.image {
            height: 200px;
            object-fit: contain;
            margin: 0 0 10px;
        }

        p {
            text-transform: uppercase;
        }
    }
}

.freeCustomer {
    padding: 45px 0;

    h3.ui.header {
        color: $purple-color;
        font-size: 28px;
        letter-spacing: 1px;

        @media (max-width: 991px) {
            font-size: 22px;
        }
    }

    .freeCustomerInner {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 80px 0 0;

        @media (max-width: 767px) {
            padding: 0 0 20px;
        }
    }

    p {
        font-size: 18px;
        line-height: 28px;
        padding: 10px 0;

        @media (max-width: 991px) {
            font-size: 15px;
            line-height: 25x;
        }
    }
}

.manageCustomer {
    padding: 50px 0;

    @media (max-width: 767px) {
        padding: 30px 0;
    }

    .ui.grid {
        .row {
            .column {
                &:first-child {
                    @media (min-width: 992px) {
                        padding-right: 45px;
                    }
                }

                &:last-child {
                    @media (min-width: 992px) {
                        padding-left: 45px;
                    }
                }
            }
        }
    }

    .manageCustomerInner {
        display: flex;
        align-items: center;
        height: 100%;

        @media (max-width: 767px) {
            padding: 15px 0;
        }

        h3.ui.header {
            color: $violet-color;
            font-size: 28px;
            letter-spacing: 1px;

            @media (max-width: 992px) {
                font-size: 22px;
            }
        }

        p {
            font-size: 18px;
            line-height: 28px;
            padding: 10px 0;
        }
    }
}

.ourCustomer {
    margin: 25px 0;
}

.stepStripWrapper {
    padding: 45px 0;

    .stepStrip {
        flex-direction: column !important;

        h2 {
            &.ui {
                &.header {
                    &.subCaption {
                        color: $white-color;
                    }
                }
            }
        }

        a {
            max-width: 150px;
            margin: 0 auto;
            font-weight: 600;
        }
    }
}

/* footer */
.footerWrapper {
    padding: 30px 0 20px;

    @media (max-width: 767px) {
        .grid {
            .four {
                flex: 0 0 50%;
                margin-bottom: 20px !important;
            }
        }
    }

    @media (max-width: 575px) {
        .grid {
            .four {
                flex: 0 0 100%;
                margin-bottom: 20px !important;
            }
        }
    }

    .footer {
        padding: 10px 0 5px !important;

        .logoImage {
            display: flex;
            padding: 0;

            img {
                max-width: 65px;
            }
        }


        p {
            font-size: 16px;
            line-height: 26px;
            padding: 10px 0;
        }

        a {
            font-size: 16px;
            line-height: 26px;
            padding: 10px 0;
            color: #280659;

            &:hover {
                color: #f54952 !important;
            }
        }

        .phoneNumber {
            display: inline-flex;
            font-size: 12px !important;
            padding: 8px 12px !important;
            margin-top: 8px;
            font-family: "latobold";
        }

        h4 {
            &.ui {
                &.header {
                    &.subCaption {
                        font-family: "latobold" !important;
                        padding-bottom: 1.4rem;
                        color: #f54952;
                        font-weight: 600;
                        padding: 0;
                        margin: 0;
                        font-size: 25px;
                    }
                }
            }
        }

        .quickLink {
            padding: 0;
            margin: 15px 0 0;

            .item {
                padding: 5px 0;
                list-style: none;
                font-size: 15px;
                color: #000;
            }
        }

        .media-socail {
            margin: 15px 0 0;

            .item {
                i.icon {
                    color: $black-color;

                    &:hover {
                        color: $orange-color;
                    }
                }
            }
        }

        p {
            line-height: 24px !important;
        }
    }
}

/*  slider  */

// .swiper-wrapper {
//     display: flex;
//     overflow: hidden;
// }
//   .swiper-slide {
//     text-align: center;
//     font-size: 18px;
//     background: #fff;

//     /* Center slide text vertically */
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: -webkit-flex;
//     display: flex;
//     -webkit-box-pack: center;
//     -ms-flex-pack: center;
//     -webkit-justify-content: center;
//     justify-content: center;
//     -webkit-box-align: center;
//     -ms-flex-align: center;
//     -webkit-align-items: center;
//     align-items: center;
//     img {
//         min-width: 200px;
//     }
//   }

//   .swiper-slide img {
//     display: block;
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//   }

//   .swiper-container {
//     width: 100%;
//     // height: 300px;
//     margin: 20px auto;
//     overflow: hidden;
//   }

.swiper-slide img {
    width: 100%;
}

.logoImage2 {
    margin: 15px 0 25px;
    display: flex;
}

.policyText {
    padding: 10px 0 15px;

    p {
        font-size: 16px;
        padding: 0 0 10px;
    }

    h3.ui.header {
        margin: 33px 0 5px;
        font-size: 20px;
    }

    .privacyText {
        font-size: 16px;
    }

    .termsPolictyText {
        font-size: 16px;
    }
}

.termsServiceText {
    padding: 10px 0 15px;

    p {
        font-size: 16px;
        padding: 2px 0 10px;
    }

    h3.ui.header {
        margin: 0px 0 5px;
        font-size: 20px;
    }

    .privacyText {
        font-size: 15px;
    }

    .termsPolictyText {
        font-size: 17px;

    }
}

p.left-margin {
    margin-left: 18px;
}

.underline {
    text-decoration: underline;
}

.emailUpdate {
    position: relative;

    .field {
        .ui.input {
            width: 100%;

            input {
                width: 100%;
                padding-right: 50px;
            }
        }
    }

    i.send.icon {
        position: absolute;
        top: 0;
        right: 0;
        color: #f54952 !important;
        cursor: pointer;
        font-size: 18px;
        border-left: 1px solid #e3e3e3;
        height: 38px;
        width: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.quickLinkPointer {
    cursor: pointer;
}

.demoBanner {
    img.ui.image {
        height: calc(100vh - 220px);
        width: 100%;
        object-fit: cover;

        @media (max-width: 767px) {
            height: auto;
        }
    }
}

.schedule-form {
    background-color: #f9f9f9;
    padding: 30px;

    .innerWrapper {
        max-width: 500px;
        width: 100%;
        margin: 0 auto;

        h1.ui.header {
            font-size: 22px;
            color: $orange-color;
            text-align: center;
            margin: 0 0 30px;
            letter-spacing: 1px;
        }

        .contactUs {
            border: 1px solid #000;
            padding: 30px 20px 20px;
            border-radius: 8px;
            position: relative;

            p.text {
                position: absolute;
                left: 50%;
                top: 0;
                transform: translate(-50%, -50%);
                background-color: #f9f9f9;
                padding: 0 25px;
                margin: 0;
                font-size: 16px;
            }
        }

        .ui.form {
            .field {
                .ui.input {
                    input {
                        border: none;

                        &::placeholder {
                            color: #999;
                        }
                    }
                }

                textarea {
                    border: none;

                    &::placeholder {
                        color: #999;
                    }
                }
            }
        }
    }
}

.accountToday {
    background-image: linear-gradient(to right, #f24852, #290e59) !important;
    padding: 25px 10px;
    text-align: center;

    h3.ui.header {
        color: $white-color;
        font-size: 22px;
    }
}

.footer {
    padding: 70px 0;

    @media (max-width: 767px) {
        padding: 30px 0;
    }

    .logo {
        img.ui.image {
            max-width: 100px;
            @media (max-width: 767px) {
                margin: auto;
            }
        }

        a {
            color: $purple-color;
            margin: 20px -10px 0;
            display: inline-block;
            font-size: 15px;
        }
    }

    .quickLink, .getInTouch {
        @media (max-width: 767px) {
            text-align: center;
        }
        h4.ui.header {
            color: $orange-color;
            font-size: 20px;
            margin: 0 0 15px;
        }

        .ui.list {
            .item {
                color: #000;
                margin: 0 0 5px;

                &:last-child {
                    margin: 0;
                }
            }
        }
    }
}
/* Responsive styles */
@media only screen and (max-width: 768px) {
    .footer .logo a {
        font-size: 14px; /* Adjust font size for smaller screens */
        margin: 10px -5px 0; /* Adjust margin for smaller screens */
    }
}

@media only screen and (max-width: 576px) {
    .footer .logo a {
        font-size: 13px; /* Further adjust font size for even smaller screens */
        margin: 5px -2px 0; /* Further adjust margin for even smaller screens */
    }
}
.increaseRevenue {
    padding: 30px 0;

    h3.ui.header {
        color: $orange-color;
        font-size: 22px;
        text-align: center;
        margin: 0 0 50px;

        @media (max-width: 991px) {
            margin: 0 0 10px;
        }
        .increaseRevenueSlide1{
            color: $orange-color;
            font-size: 22px;
            text-align: center;
            margin: 0 0 50px;
        }

        span {
            &:first-child {
                color: $purple-color;
            }

            &:last-child {
                color: $violet-color;
            }
        }
    }

    .swiper-container {
        padding: 20px 0;

        .swiper-button-prev {
            background: #fff url(../images/left-arrow.png);
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
            height: 50px;
            width: 50px;
            background-size: 50%;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 50px;
            cursor: pointer;
            position: absolute;
            left: 2px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 99;
        }

        .swiper-button-next {
            background: #fff url(../images/right-arrow.png);
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
            height: 50px;
            width: 50px;
            background-size: 50%;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 50px;
            cursor: pointer;
            position: absolute;
            right: 2px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 99;
        }

        .swiper {
            display: flex;
            gap: 40px;
            align-items: center;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            max-width: 95%;
            margin: 0 auto;
            padding: 40px;
            box-sizing: border-box;

            @media (max-width: 767px) {
                flex-direction: column;
            }

            .SliderImg {
                flex: 1;

                img.ui.image {
                    width: 100%;
                }
            }

            .revenueInfo {
                text-align: center;
                display: flex;
                align-items: center;
                height: 100%;
                flex: 1;
                justify-content: center;

                p {
                    font-size: 18px;
                    padding: 0 0 20px;
                }

                a {
                    font-size: 22px;
                    color: $purple-color;
                }
            }
        }
    }
}

.phone-number {
    width: 100% !important;
    margin-bottom: 6px !important;
}

.phone-input {
    input {
        padding-left: 51px !important;
    }
}

.ContentArea {
    font-size: 11px;
    position: absolute;
    left: 183px;
    top: 40px;
    color: #f54952 !important;
}
// header
.siteHeader {
    position: sticky;
    top: 0;
    z-index: 99;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
// footer
.socialIcon {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    grid-gap: 25px;
    @media (max-width: 767px) { 
        justify-content: center;
    }
    li{
        i {
            font-size: 24px;
            &.fa-instagram {
                color: #f28a21;
            }
            &.fa-facebook, &.fa-linkedin {
                color: #007ab5;
            }
            &.fa-youtube {
                color: #f70000;
            }
        }
    }
}

.getInTouch {    
    .address, .email {
        font-size: 16px;
        display: flex;
        grid-gap: 15px;
        @media (max-width: 767px) { 
            justify-content: center;
        }
        i {
            font-size: 20px;
            color: #d03e53;
            min-width: 20px;
        }
    }
    .email {
        margin-top: 10px;
        a{
            display: flex;
            grid-gap: 15px;
            color: #000;
        }
    }
}
.copyRight {
    text-align: center;
    margin-top: 15px;
}

// slider title
.increaseRevenueSlide1 {
    color: #f54a52 !important;
}
.increaseRevenueSlide2 {
    color: #361e64 !important;
}
.increaseRevenueSlide3 {
    color: #ad2b67 !important;
}