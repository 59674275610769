@import "variables.scss";
.common-form {
    height: 100vh;
    align-items: center !important;
    margin: 0 auto !important;
    padding: 10px 0 !important;
    overflow-y: auto;
    > .column {
        max-width: 450px;
        padding: 15px 30px !important;
        margin-bottom: 0 !important;
        .image {
            margin-bottom: 20px;
        }
        .form {
            .field {
                label {
                    text-align: left;
                    font-weight: 100 !important;
                }
                .input {
                    input {
                        border-radius: 30px !important;
                    }
                }
            }
        }
        .empty {
            margin: 5px;
        }
        .circular {
            .basic {
                margin: 20px 20px 0;
                min-width: 50px !important;
                min-height: 50px !important;
                line-height: 32px !important;
                font-size: 15px !important;
                font-weight: 100 !important;
                &:hover {
                    background-color: $orange-color !important;
                    color: $white-color !important;
                }
            }
            .empty-label {
                border: none !important;
                color: $orange-color !important;
                font-size: 12px !important;
                &:hover {
                    background-color: transparent !important;
                    color: $orange-color !important;
                }
            }
        }
        .btn-row {
            max-width: 260px;
        }
    }
}
.location-selection {
    > .column {
        max-width: 600px;
        h1.ui.header {
            margin: 0;
        }
        .location-boxes {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 70px;
            flex-wrap: wrap;
            > .card {
                max-width: 150px;
                height: 150px;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
                border: none !important;
                color: $black-color;
                margin: 0 0 40px 0;
                &:hover {
                    background-color: $orange-color !important;
                    color: $white-color !important;
                }
            }
        }
    }
}
.imageParent {
    max-width: 100px;
    height: 100px;
    margin: 0 auto 15px;
    border-radius: 50%;
    overflow: hidden;
    img.ui.image {
        height: 100%;
        max-width: 100%;
        object-fit: cover;
    }
}

/* signin form */
.signinFormOuter {
    justify-content: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 auto 30px;
    padding: 10px;
    overflow-y: auto;
}
.signinForm {
    max-width: 430px;
    width: 100%;
    margin: 0 auto;
    .orange-react-btn {
        width: 100%;
    }
    h3 {
        text-align: center;
        color: #f54952;
        font-size: 1.75rem;
        padding: 0;
        margin: 0;
        line-height: 1.2;
        font-family: "latomedium" !important;
        font-weight: 500;
    }
    p {
        font-family: "latoregular";
        text-align: center;
        padding: 10px 0 20px;
        margin: 0;
        font-size: 1rem;
        line-height: 1.5;
        .star {
            color: $orange-color;
            font-size: 22px;
        }
    }
    form {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 15px;
        width: 100%;
        &.ui {
            &.form {
                .field {
                    &.checkBoxWrap {
                        margin: 1.5rem 0 !important;
                    }
                    label {
                        display: inline-block;
                        margin-bottom: 0.5rem;
                        font-family: "latoregular";
                        font-size: 1rem;
                        line-height: 1.5;
                        color: #212529;
                        text-align: left;
                        display: flex;
                        align-items: center;
                        font-weight: 400;
                        &:before {
                            top: auto;
                        }
                    }
                    input {
                        display: block;
                        width: 100%;
                        height: calc(1.5em + 0.75rem + 2px);
                        padding: 0.375rem 0.75rem;
                        font-size: 1rem;
                        line-height: 1.5;
                        font-family: "latoregular";
                        color: #495057;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                        min-height: 38px;
                    }
                    .for-inline {
                        margin-bottom: 0 !important;
                        padding: 0 !important;
                    }
                    .chooseOption {
                        font-size: 1rem;
                        line-height: 1.5;
                        color: #212529;
                        text-align: left;
                        font-family: "latoregular";
                        margin-bottom: 0;
                        &:last-child {
                            margin: 5px 0 0;
                        }
                        label {
                            font-family: "latoregular";
                            font-size: 1rem;
                            line-height: 1.5;
                            color: #212529;
                            position: relative;
                            margin-bottom: 0;
                            vertical-align: top;
                            &::before {
                                position: absolute;
                                top: 4px;
                                left: 1px;
                                display: block;
                                width: 1rem;
                                height: 1rem;
                                background: no-repeat 50% / 50% 50%;
                                color: #fff;
                                z-index: 0;
                                font-size: 10px;
                            }
                            &::after {
                                position: absolute;
                                top: 4px;
                                left: 1px;
                                display: block;
                                width: 1rem;
                                height: 1rem;
                                content: "\e800";
                                background: no-repeat 50% / 50% 50%;
                                color: #fff;
                                z-index: 0;
                                font-size: 10px;
                            }
                        }
                        input {
                            &:checked {
                                ~ {
                                    label {
                                        &::before {
                                            color: #fff;
                                            border-color: #f54952;
                                            background-color: #f54952;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .ui.basic.red.label {
                        margin: 0;
                        font-weight: 400 !important;
                        background: #fff !important;
                        border: 1px solid #e0b4b4 !important;
                        color: #9f3a38 !important;
                    }

                    /* increase decrease */

                    .number-input {
                        display: flex;
                        align-items: self-start;
                        i.icon {
                            height: 40px !important;
                            width: 40px !important;
                            display: flex !important;
                            align-items: center;
                            justify-content: center;
                            margin: 0 !important;
                            cursor: pointer;
                            &:first-child {
                                border-radius: 6px 0 0 6px;
                            }
                            &:last-child {
                                border-radius: 0 6px 6px 0;
                            }
                        }
                        .ui.fluid.input {
                            > input {
                                border-radius: 0 !important;
                                height: 40px;
                                text-align: center;
                            }
                        }
                        .value-button {
                            display: inline-block;
                            border: 1px solid #ddd;
                            margin: 0px;
                            min-width: 40px;
                            height: 38px;
                            text-align: center;
                            vertical-align: middle;
                            padding: 11px 0;
                            background-color: #f82f51;
                            -webkit-touch-callout: none;
                            -webkit-user-select: none;
                            -khtml-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                            user-select: none;
                            font-size: 30px;
                            line-height: 19px;
                            color: #fff;
                        }
                        input {
                            &#number {
                                text-align: center;
                                border: none;
                                border-top: 1px solid #ddd;
                                border-bottom: 1px solid #ddd;
                                margin: 0px;
                                width: 100%;
                                height: 38px;
                            }
                        }
                        .value-button {
                            display: inline-block;
                            border: 1px solid #ddd;
                            margin: 0px;
                            min-width: 40px;
                            height: 38px;
                            text-align: center;
                            vertical-align: middle;
                            padding: 11px 0;
                            background-color: #f82f51;
                            -webkit-touch-callout: none;
                            -webkit-user-select: none;
                            -khtml-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                            user-select: none;
                            font-size: 30px;
                            line-height: 19px;
                            color: #fff;
                        }
                    }
                }
                .btn-orange {
                    color: #fff;
                    background-color: #f54952;
                    border-color: #f54952;
                    border-radius: 5px;
                    width: 100%;
                    font-family: "latobold";
                    padding: 5px 0;
                    display: inline-block;
                    text-align: center;
                    vertical-align: middle;
                    user-select: none;
                    border: 1px solid transparent;
                    font-size: 1rem;
                    line-height: 1.5;
                    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
                        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                    &:hover {
                        background-color: #fff;
                        color: #f54952;
                        border: 1px solid;
                    }
                }
                .alreadyRegiser {
                    padding-top: 1.5rem !important;
                    p {
                        border-top: 1px solid #e3e3e3;
                        padding-top: 10px;
                        font-family: "latoregular";
                        font-size: 1rem;
                        line-height: 1.5;
                        padding-bottom: 0;
                        a {
                            color: #280659;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }
}

.tagger {
    .ui.label {
        max-width: 100%;
        position: relative;
        max-height: 50px;
        overflow-y: auto;
        padding-right: 20px !important;
        .delete.icon {
            position: absolute;
            top: 6px;
            right: 6px;
        }
    }
    .ui.multiple.search.dropdown {
        // padding-top: 0;
        // padding-bottom: 0;
        > input.search {
            margin: 0px 10px;
            display: inline-block !important;
            height: 26px !important;
            min-height: 26px !important;
        }
        // .text {
        //     top: 4px;
        // }
    }
}

.for-inline {
    display: inline-block !important;
    margin-left: 10px !important;
    padding-bottom: 3px !important;
}

.inline-checkbox {
    display: inline-block !important;
}
