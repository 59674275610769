$orange-color:#f54952;
$violet-color:#280e59;
$purple-color:#ae2c68;
$indigo-color:#341671;
$deepviolet-color:#280659;
$maroon-color:#ae2d68;
$white-color:#fff;
$black-color:#000;
$spanish-gray:#999;
$spanish-gray:#999;
$alice-blue:#F9FAFB;
$gray-box:#f9f9f9;
$snow-color:#fbfbfb;
$gainsboro-color:#e3e3e3; 
$white-smoke-color:#f5f5f5;

@mixin horizontal-scroll {
	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		border-radius: 10px;
		background-color: $white-smoke-color;
	}

	&::-webkit-scrollbar {
		width: 10px;
		background-color: $white-smoke-color;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: $orange-color;
		&:hover {
			background-color: $orange-color;
		}
	}
}

@mixin vertical-scroll {
	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		border-radius: 10px;
		background-color: $white-smoke-color;
	}

	&::-webkit-scrollbar {
		height: 10px;
		background-color: $white-smoke-color;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: $orange-color;
		&:hover {
			background-color: $orange-color;
		}
	}
}